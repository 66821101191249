.form-div{
    background-color: #f5f5f5;
}
.form-div label{
    color: rgb(0, 0, 0);
    font-weight: bold;
}
.form-div input{
    background-color: rgb(226, 225, 225);
    font-size: 12px;
}
.form-div textarea{
    background-color: rgb(226, 225, 225);
    font-size: 12px;
}
.form-div textarea:focus{
   box-shadow: none;
   outline: none;
   border: none;
}
.form-div input:focus{
   box-shadow: none;
   outline: none;
   border: none;
}
.select-custom{
    font-size: 12px;
    background-color: rgb(226, 225, 225);
}
.select-custom option{
    font-size: 12px;
}
.select-custom option:focus{
    outline: none;
    box-shadow: none;
}