/* Card.module.css */
.card {
    width: 250px;
    height: 280px;
    padding: .8em;
    background: #f5f5f5;
    position: relative;
    overflow: visible;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 5px;
  }
  
  .cardImg {
    height: 40%;
    width: 100%;
    border-radius: .5rem;
    overflow: hidden;
    transition: .3s ease;
  }
  
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  .cardInfo {
    padding-top: 10%;
  }
  
  svg {
    width: 20px;
    height: 20px;
  }
  
  .cardFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
  }
  
  /* Text */
  .textTitle {
    font-size: 0.8em;
    line-height: 1.5;
    color: black;
  }
  
  .textBody {
    font-size: .9em;
    padding-bottom: 10px;
  }
  
  /* Button */
  .cardButton {
    border: 1px solid #252525;
    display: flex;
    padding: .3em;
    cursor: pointer;
    border-radius: 50px;
    transition: .3s ease-in-out;
  }
  
  /* Hover */
  .cardImg:hover {
    transform: translateY(-25%);
    box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
  }
  
  .cardButton:hover {
    border: 1px solid #ffcaa6;
    background-color: #ffcaa6;
  }
  