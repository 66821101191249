/* General styling */
.seller-dashboard {
    background-color: #121212;
    color: #E0E0E0;
    min-height: 100vh;
    display: flex;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #6c757d;
  }
  
  .dashboard-header h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .user-icon {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
  
  .user-avatar {
    background-color: #EF5B2B;
    color: #121212;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Stats section styling */
  .stats-card {
    padding: 1rem;
    background-color: #343a40;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .stats-title {
    font-size: 16px;
    margin-bottom: 0.625rem;
  }
  
  .stats-value {
    font-size: 24px;
    font-weight: bold;
    color: #EF5B2B;
  }
  
  /* Chart and Reviews section styling */
  .chart-container {
    padding: 1rem;
    background-color: #343a40;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chart-title {
    font-size: 18px;
    margin-bottom: 0.9375rem;
  }
  
  .chart-wrapper {
    height: 300px;
  }
  
  .reviews-container {
    padding: 1rem;
    background-color: #343a40;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 380px;
    overflow-y: auto;
  }
  
  .review-item {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #6c757d;
  }
  
  .review-username {
    color: #EF5B2B;
  }
  


  @media(max-width: 991px){
    .seller-dashboard {
        display: flex;
        flex-direction: column;
      }
  }