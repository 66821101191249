/* Signup.css */  
  .signup-container {
    width: 100%;
    max-width: 430px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
    border-radius: 10px;
  }
  
  .signup-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #f4582f;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-form input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .signup-form button {
    padding: 10px;
    background-color:#f4582f;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-form button:hover {
    background-color: #ff6a43;
  }
  
  .role-selection{
    display: flex;
    margin: 5px 0px;
  }
  
  .role-selection label{
    margin-left: 7px;
    color: #000000;
  }
  
  .mr-text{
    margin-right: 3px;
  }

  .account-info-text{
    margin-top: 1rem;
    text-align: center;
    font-size: 14px;
  }
  
  .account-info-text-link{
    color: #ED5C2C;
  }

  /*Success toast*/
.custom-toast-success {
  background-color: green; /* Success color */
  color: white; /* Text color */
}

.custom-toast-error {
  background-color: red; /* Error color */
  color: white; /* Text color */
}

.custom-toast-info {
  background-color: blue; /* Info color */
  color: white; /* Text color */
}

.custom-toast-warning {
  background-color: orange; /* Warning color */
  color: white; /* Text color */
}

  
  /* Responsive design */
  @media (max-width: 768px) {
    .signup-container {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .signup-container {
      padding: 10px;
    }
  
    .signup-form input,
    .signup-form button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 367px){
    .role-selection{
      flex-direction: column;
    }
  }