.list-group-item {
  border: none;
}

.list-group-item:hover {
  background-color: #eef;
  cursor: pointer;
}
.search-input {
  font-size: 0.8rem;
  border: none;
  background-color: #eef;
}
.search-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
}
.userName-text {
  font-size: 0.8rem;
}
.userrecent-text {
  font-size: 0.7rem;
  color: rgb(100, 100, 100);
}
.time-text {
  font-size: 0.8rem;
}
.contact-list-container {
  max-height: 65vh;
  overflow-y: scroll;
}

.contact-list-container::-webkit-scrollbar {
  display: none;
}

.contact-list-container {
  scrollbar-width: none;
}
.chat-window-div{
    background-color: #ec5b2c3b;
}
.sender-msg-text{
    background-color: #ec5b2c;
}
.message-input{
    outline: none;
    border: none;
    box-shadow: none;
}
.message-input:focus{
    outline: none;
    border: none;
    box-shadow: none;
}
.submit-message-btn{
    background-color: #ec5b2c;
    border: none;
}
.submit-message-btn:hover{
    background-color: #e0643b;
    border: none;
}
.upper-user-container{
    padding: 1rem;
    padding-left: 2rem;
    z-index: 200;
    background-color: #ed977c;
}
/* ChatComponent.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid #ec5b2c;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #ec5b2c;
}
