.Three-buttonsContainer{
    display: flex;
    flex-direction: column;
    padding: 40px;
}

/* From Uiverse.io by cssbuttons-io */ 
button {
    font-size: 17px;
    padding: 1em 2.7em;
    font-weight: 500;
    background: #ef5a2c;
    color: white;
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 0.6em;
    cursor: pointer;
  }
  
  /* .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0.6em;
    margin-top: -0.25em;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    );
  } */
  
  .label {
    position: relative;
    top: -1px;
  }
  
  .transition {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: #ff8d63;
    border-radius: 9999px;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  button:hover .transition {
    width: 14em;
    height: 14em;
  }
  
  button:active {
    transform: scale(1);
  }
  

  @media (max-width:400px){
    button {
        font-size: 11px;
      }
  }