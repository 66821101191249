.settingsPage {
  background: linear-gradient(135deg, #ee552bb9, #f2572cb5);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.settingsContainer {
  width: 100%;
  max-width: 900px;
}

.card {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  overflow: hidden;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.logoWrapper {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.title {
  font-size: 1rem;
  color: #333;
  margin-top: 15px;
}

.form {
  margin-top: 20px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.formGroup {
  flex: 1;
}

.label {
  display: block;
  font-weight: bold;
  font-size: 0.7rem;
  margin-bottom: 5px;
  color: #444;
}

.input,
.select {
  width: 100%;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.7rem;
  transition: border-color 0.3s;
}

.input:focus,
.select:focus {
  border-color: #f2582c;
  outline: none;
}

.fileInput {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 0.7rem;
}

.buttonContainer {
  text-align: center;
}

.saveButton {
  background: #c95b3d;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 0.7rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.saveButton:hover {
  background: #f2582c;
}

.logoWrapper {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fileUploadWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.fileInputLogo {
  display: none; /* Hide default input */
}

.fileUploadLabel {
  background-color: #f2582c;
  color: white;
  padding: 1px 11px;
  font-size: 0.8rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
}

.fileUploadLabel:hover {
  background-color: #e37b5f;
}

.textareaWrapper {
  margin-bottom: 3px;
}

.labeltextarea {
  display: block;
  font-size: 0.7rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.textarea {
  width: 100%;
  padding: 10px;
  font-size: 0.7rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical; 
  font-family: inherit;
  transition: border-color 0.3s;
}

.textarea:focus {
  border-color: #f2582c;
  outline: none;
  box-shadow: 0 0 2px #f2582c;
}
