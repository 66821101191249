.carousel-control-prev,
.carousel-control-next {
  background-color: #EF5B2B; /* Background color for the arrows */
  border-radius: 50%; /* Optional: makes the background round */
  color: white; /* Arrow color */
  opacity: inherit;
  width: 52px;
  height: 52px;
  top: 50%;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: #d45a27; /* Darker shade on hover (optional) */
}

.carousel-inner{
  margin: auto;
  width: 96%;
  padding: 0 1.5rem;
}
.carousel-indicators{
  margin-bottom: 0;
}
.category-icon-wrapper{
    font-size: 27px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(239, 91, 43);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    color: rgb(239, 91, 43);
}

.category-name{
    text-align: center;
    margin-top: 10px;
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
}