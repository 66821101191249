.outline-buttons{
    font-size: 12px;
    color: black;
}
.card-custom{
    border: 1px solid rgb(90, 90, 90);
    background-color: white;
    cursor: pointer;
}
.card-custom:hover{
    background-color: rgb(175, 170, 170);
    color: black;
}
.card-title-custom{
    font-size: 10px;
    color: black
}
.card-text-custom{
    font-size: 10px;
    margin: 0;
}
.custom-arrow-icon{
    font-size: 13px;
}
.card-body-custom{
    height: 210px;
}
.card-text-value{
    font-size: 10px;
    color: black;
    font-weight: bold;
}
.dropdown-item{
    font-size: 10px;
}
.dropdown-item:hover{
    background-color: #f55926;
    color: white;
}
.chart-wrapper{
    height: 150px;
}

.review-item {
    border-bottom: 1px solid #e0e0e0; /* Add a subtle border between reviews */
    padding: 10px 0; /* Add padding for spacing */
    margin-bottom: 10px; /* Add some space between each review */
  }
  
  .review-item:last-child {
    border-bottom: none; /* Remove border on the last review */
  }
  
  .review-username {
    font-weight: bold;
    font-size: 0.5rem; /* Slightly larger than the review text */
    color: #333; /* Darker color for better readability */
  }
  
  .review-text {
    font-size: 0.5rem;
    color: #666; /* Light color for the review text */
    line-height: 1.4;
  }
  
  .text-muted {
    color: #888; /* Muted color for product name */
  }
  