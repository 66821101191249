.help-center-search {
    height: 250px;
    background-image: url('../Assets/help-center.jpg'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    color: #fff;
  }
  .searchinput{
    border: none;
    outline: none;
  }
  .searchinput:focus{
    border: none;
    outline: none;
    box-shadow: none;
  }

  .accordion-button {
    background-color: #f8f9fa; /* Light background for buttons */
    color: #333; /* Dark text color */
    border: 1px solid #dee2e6; /* Border color */
  }
  
  .accordion-button:focus {
    box-shadow: none; /* Remove focus outline */
  }
  
  .accordion-button:not(.collapsed) {
    background-color: #e2e6ea; /* Darker background when expanded */
    color: #000; /* Black text when expanded */
  }
  
  .accordion-body {
    background-color: #ffffff; /* White background for body */
    color: #555; /* Grey text color */
  }
  .card {
    background-color: #f8f9fa; /* Light background for cards */
  }
  .card-title {
    font-weight: bold; /* Bold titles for emphasis */
  }
  .card-text {
    color: #6c757d; /* Muted text color for better readability */
  }
  