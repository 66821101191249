/* Hero Section */
.hero-section {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
              url("https://cdn.pixabay.com/photo/2016/11/22/21/57/apparel-1850804_1280.jpg") 
              no-repeat center center;
  background-size: cover;
  color: white;
  padding: 10rem 0;
}

.stroked-text-shadow {
  color: white;
  text-shadow: 
    -2px -2px 0 #EF5A2F, 
    2px -2px 0 #EF5A2F, 
    -2px 2px 0 #EF5A2F, 
    2px 2px 0 #EF5A2F,
    -3px -3px 0 #EF5A2F,
    3px -3px 0 #EF5A2F,
    -3px 3px 0 #EF5A2F,
    3px 3px 0 #EF5A2F;
  font-size: 60px;
  font-weight: bold;
}
.stroked-text-p {
  /* color: white;
  text-shadow: 
    -2px -2px 0 #EF5A2F, 
    2px -2px 0 #EF5A2F, 
    -2px 2px 0 #EF5A2F, 
    2px 2px 0 #EF5A2F;    
  font-size: 35px; */
  color: white; /* Fills the text with white color */
  -webkit-text-stroke: 2px #EF5A2F; /* Adds a 2px red stroke to the text */
  text-shadow: none;
  font-size: 40px;
  font-weight: bold;
  
}

.search-box {
  padding: 10px 15px;
  width: 50%;
  max-width: 400px;
    border: 2px solid #ccc;
    border-radius: 25px;
    outline: none;
}

.search-btn {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #EF5A2F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-btn:hover {
  background-color: #ff734d;
}

@media (max-width: 804px) {
  .hero-section {
    padding: 150px 0;
  }
}

@media (max-width: 550px) {
  .hero-section {
    padding: 90px 0;
  }
  .hero-section h1 {
    font-size: 2rem;
  }
  .hero-section p {
    font-size: 1.2rem;
  }
}


.search-box {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.search-box:focus {
  outline: none;
  /* border-color: #007bff; */
  border: none;
  box-shadow: none;
}
.search-hero-section{
  outline: none;
  box-shadow: rgba(228, 125, 29, 0.25) 0px 6px 12px -2px, rgba(250, 140, 5, 0.3) 0px 3px 7px -3px;
  color: #f0592a;
}
.search-hero-section:hover{
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  outline: none;
  
}
.search-hero-section:focus{
  outline: none;
  box-shadow: rgba(249, 100, 13, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: #EF5A2F;
  color: #f0592a;
}

@media (max-width: 550px) {
  .hide-for-responsive {
   display: none;
   visibility:hidden; 
  }
}