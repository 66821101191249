.body {
    margin: 0;
    font-family: "Roboto Condensed", sans-serif;
}


/* Global Styles */
.home-container {
    /* padding: 20px; */
    font-family: "Roboto Condensed", sans-serif;
    width: 100%;
}



/* Hero Section */


/* Featured Products Section */
.featured-products {
    font-family: "Roboto Condensed", sans-serif;
}

.product-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}

.product-card {
    background-color: #f8f9fa;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: calc(33.33% - 30px);
    margin-bottom: 20px;
}

.product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.product-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.product-details {
    padding: 20px;
    text-align: center;
}

.product-details h3 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.product-details p {
    font-size: 1.1rem;
    color: #EF5B2B;
    margin-bottom: 15px;
}

.view-details-btn {
    background-color: #EF5B2B;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.view-details-btn:hover {
    background-color: #d44e26;
}


/* Banner Section */
.banner {
    margin-top: 40px;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
}

.banner img {
    max-width: 100%;
    height: auto;
    font-family: "Roboto Condensed", sans-serif;
}



.footer {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  font-family: "Roboto Condensed", sans-serif;
}

.footer-section {
  max-width: 200px;
}

.footer-section h4 {
  font-size: 14px;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 12px;
  margin: 5px 0;
}

.app-buttons img, .payment-methods img {
  width: 100px;
  margin-right: 10px;
}

.app-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.payment-methods {
  display: flex;
  justify-content: flex-start;
}

.no-border-input:focus{
  border: "none";
  outline: "none";
}

input {
    border: none;
    /* border-bottom: 2px solid #000; */
    outline: none;  /* Removes the default focus outline */
}

input:focus {
    border-bottom: 2px solid #000; /* Ensures the bottom border stays the same on focus */
}




