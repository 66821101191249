* {
    box-sizing: border-box;
  }
    
  .login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
    border-radius: 8px;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 500;
    color: #f4582f;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #000000;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .login-form input:focus {
    border-color: #007bff;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #f4582f;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #ff6a43;
  }

  .account-info-text{
    margin-top: 1rem;
    text-align: center;
    font-size: 14px;
  }
  
  .account-info-text-link{
    color: #ED5C2C;
  }

  @media (max-width: 600px) {
    .login-title {
      font-size: 22px;
    }
  
    .login-button {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .topLogin-container {
        padding: 10px;
    }
  }
  
  @media (max-width: 450px) {
    .login-container {
      margin: 50px 20px;
    }
  }