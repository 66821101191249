

   .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .productCard-Detail {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
    transform: translateY(0px);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid rgb(224, 224, 224);
  }

  .padding-card{
    padding: 15px;
  }
  
  .productName {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0px;
    color: rgb(51, 51, 51);
    cursor: pointer;
  }
  
  .product-description {
    font-size: 14px;
    color: rgb(85, 85, 85);
    margin: 5px 0px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .product-price {
    font-size: 16px;
    color: rgb(40, 167, 69);
    margin: 5px 0px;
  }
  
  .no-products-message {
    font-size: 1.1em;
    color: #999;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .product-card {
      width: 200px;
      padding: 10px;
    }
  
    .product-name {
      font-size: 1.1em;
    }
  
    .product-description {
      font-size: 0.85em;
    }
  
    .product-price {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .product-card {
      width: 100%;
    }
  }
  