/* Footer Section */
/* .footer-section {
    background-color: #343a40;
    color: #ffffff;
    padding: 40px 0;
  }
  
  .footer-about h5,
  .footer-links h5,
  .footer-social h5 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #f8f9fa;
  }
  
  .footer-about p {
    font-size: 1rem;
    color: #d3d3d3;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 10px;
  }
  
  .footer-links ul li a {
    color: #d3d3d3;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links ul li a:hover {
    color: #ffffff;
  }
  
  .footer-social .social-icons {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
  
  .footer-social .social-icons a {
    color: #d3d3d3;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-social .social-icons a:hover {
    color: #ffffff;
  }
  
  .footer-bottom {
    margin-top: 20px;
  }
  
  .footer-bottom p {
    font-size: 1rem;
    color: #d3d3d3;
  } */


  /* Footer Section */
.footerSection {
  background-color: #343a40;
  color: #ffffff;
  padding: 40px 0;
}

.footerAbout h5,
.footerLinks h5,
.footerSocial h5 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #f8f9fa;
}

.footerAbout p {
  font-size: 1rem;
  color: #d3d3d3;
}

.footerLinks ul {
  list-style: none;
  padding: 0;
}

.footerLinks ul li {
  margin-bottom: 10px;
}

.footerLinks ul li a {
  color: #d3d3d3;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footerLinks ul li a:hover {
  color: #ffffff;
}

.footerSocial .socialIcons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.footerSocial .socialIcons a {
  color: #d3d3d3;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footerSocial .socialIcons a:hover {
  color: #ffffff;
}

.footerBottom {
  margin-top: 20px;
}

.footerBottom p {
  font-size: 1rem;
  color: #d3d3d3;
}
