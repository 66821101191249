/* Sidebar styling */
.sidebar {
    width: 250px;
    background-color: #1E1E1E;
    color: #E0E0E0;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
  
  /* Logo styling */
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 200px;
    height: auto;
  }
  
  /* Navigation list styling */
  .nav-list {
    list-style-type: none;
    padding: 0;
  }
  
  .nav-item {
    margin: 15px 0;
  }
  
  .nav-links {
    text-decoration: none;
    color: #E0E0E0;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 4px;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .nav-links-active {
    background-color: #2C3E50;
    color: #EF5B2B;
  }
  
  .nav-icon {
    font-size: 18px;
  }
  
  /* Logout button styling */
  .logout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding: 20px 0;
  }
  
  .logout-button {
    background-color: #EF5B2B;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
  

  @media(max-width: 991px){
    .sidebar {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
      }
      .nav-list {
        display: flex;
        flex-wrap: wrap;
    }
    .logout-container {
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin-top: 0;
      }
  }